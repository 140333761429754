import { FC } from "react";
import { styled } from "@mui/system";

const ObjectComponent = styled("object")({
  width: "100%",
  height: "100vh",
  "&>:first-child": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
  },
  margin: "20px 0",
});

const DocumentPreview: FC<any> = ({ url }) => (
  <ObjectComponent
    aria-label="Prévisualisation bon de commande"
    type="application/pdf"
    height="this.height=window.innerHeight;"
    data={`${url}#toolbar=0&statusbar=0&messages=0&zoom=scale&view=FitH`}
  />
);

export default DocumentPreview;
