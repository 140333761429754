import { createTheme } from "@mui/material/styles";
import bg from "./assets/images/bg.svg";

const defaultTheme = createTheme();
const theme = createTheme({
  palette: {
    text: {
      primary: "#231E1F",
      secondary: "#6B6B6B",
    },
    primary: {
      main: "#FFD503",
      light: "#FFD50305",
    },
    secondary: {
      main: "#FFD503",
      light: "#FFD50305",
    },
    background: {
      default: "#E5E5E5",
      paper: "#FFFFFF",
    },
    grey: {
      700: "#6B6B6B",
      400: "#AAAAAA",
      200: "#C7C7C7",
      100: "#F3F5F6",
    },
    divider: "#C7C7C7",
  },
  typography: {
    fontFamily: "Helvetica Neue, Arial, sans-serif",
    h1: {
      fontFamily: "Helvetica Neue, Arial",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 28,
      lineHeight: "36px",
    },
    h2: {
      fontFamily: "VAG Rounded Std, Arial",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 32,
      lineHeight: "42px",
    },
    h3: {
      fontFamily: "Helvetica Neue, Arial",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 24,
      lineHeight: "34px",
    },
    h4: {
      fontFamily: "VAG Rounded Std, Arial",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 20,
      lineHeight: "26px",
    },
    h5: {
      fontFamily: "VAG Rounded Std, Arial",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 14,
      lineHeight: "26px",
    },
    subtitle1: {
      fontFamily: "Helvetica Neue, Arial",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 18,
      lineHeight: "27px",
    },
    subtitle2: {
      fontFamily: "Helvetica Neue, Arial",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 16,
      lineHeight: "24px",
    },
    body1: {
      fontFamily: "Helvetica Neue, Arial",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 14,
      lineHeight: "21px",
    },
    body2: {
      fontFamily: "Helvetica Neue, Arial",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 12,
      lineHeight: "16px",
    },
  },
  shape: {
    borderRadius: 12,
  },
  shadows: {
    ...defaultTheme.shadows,
    1: "0px -4px 32px 0px rgba(0, 0, 0, 0.15)",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 652,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: `url(${bg})`,
          backgroundPosition: "bottom right",
          backgroundRepeat: "no-repeat",
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          [theme.breakpoints.up("sm")]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(7),
            paddingRight: theme.spacing(7),
          },
          "&:last-child": {
            borderBottomColor: theme.palette.primary.main,
            borderBottomStyle: "solid",
            borderBottomWidth: 4,
          },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 14,
          lineHeight: "20px",
          fontWeight: 700,
          padding: "12px 32px",
          borderRadius: 100,
        },
        contained: ({ theme }) => ({
          background: theme.palette.common.black,
          color: theme.palette.common.white,
          "&:hover, &.Mui-focusVisible": {
            background: theme.palette.grey[700],
          },
          "&.Mui-disabled": {
            background: theme.palette.grey[200],
          },
        }),
        outlined: ({ theme }) => ({
          color: theme.palette.common.black,
          borderColor: theme.palette.common.black,
          "&:hover, &.Mui-focusVisible": {
            borderColor: theme.palette.grey[700],
            color: theme.palette.grey[700],
            background: theme.palette.common.white,
          },
          "&.Mui-disabled": {
            borderColor: theme.palette.grey[200],
            color: theme.palette.grey[200],
            background: theme.palette.common.white,
          },
        }),
        text: ({ theme }) => ({
          color: theme.palette.common.black,
          padding: 0,
          "&:hover, &.Mui-focusVisible": {
            color: theme.palette.grey[700],
            background: theme.palette.common.white,
          },
          "&.Mui-disabled": {
            color: theme.palette.grey[200],
            background: theme.palette.common.white,
          },
        }),
      },
    },
  },
});

export default theme;
