import { FC } from "react";
import { Typography, Divider, Button, CardContent, Stack, Card } from "@mui/material";

import Layout from "containers/layout/Layout";
import NotFoundException from "exceptions/NotFoundException";
import KeyNumber from "components/key-number/KeyNumber";
import UserIcon from "components/icons/UserIcon";
import ShoppingCartIcon from "components/icons/ShoppingCartIcon";
import LayoutIcon from "components/icons/LayoutIcon";

const ErrorPage: FC<{ error: Error; resetErrorBoundary?: any }> = ({ error }) => {
  let title = "Oups, page introuvable";
  let text = "Désolé, mais la page que vous avez demandé n’existe pas. ";

  if (!(error instanceof NotFoundException)) {
    title = "Maintenance";
    text = "Désolé, mais le site est actuellement en maintenance. En attendant, vous pouvez consulter notre site :";
  }

  return (
    <Layout>
      <Card>
        <CardContent>
          <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Typography variant="h1" textAlign="center">
              {title}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" textAlign="center" sx={{ maxWidth: 388 }}>
              {text}
            </Typography>
            {/* @TODO put href to brand home */}
            <Button variant="contained" href="#">
              Retour au site de la radio
            </Button>
          </Stack>
        </CardContent>
        <Divider />
        <CardContent>
          <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Typography variant="subtitle1">3 bonnes raisons de se lancer</Typography>
            <KeyNumber title="+ de 40M" description="d’auditeurs quotidiens, soit 90% des français écoutent la radio" logo={<UserIcon />} />
            <KeyNumber
              title="58%"
              description="des Français ont écouté la radio dans les 15 minutes avant un acte d’achat"
              logo={<ShoppingCartIcon />}
            />
            <KeyNumber
              title="69%"
              description="des campagnes publicitaires radio engendrent entre 5 et 30% de requêtes sur le site web"
              logo={<LayoutIcon />}
            />
            {/* @TODO put href to quote form */}
            <Button variant="contained" href="#">
              Demander un devis
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Layout>
  );
};

export default ErrorPage;
