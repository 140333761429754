import { FC, ReactNode } from "react";
import { Container } from "@mui/material";
import { styled } from "@mui/system";

const ContainerComponent = styled(Container)(({ theme }) => ({
  marginTop: 64,
  marginBottom: 22,
  [theme.breakpoints.up("sm")]: {
    marginTop: 136,
    marginBottom: 53,
  },
  position: "relative",
}));

const Content: FC<{ children: ReactNode }> = ({ children }) => {
  return <ContainerComponent maxWidth="sm">{children}</ContainerComponent>;
};

export default Content;
