import { FC } from "react";
import { styled } from "@mui/system";

const HeaderComponent = styled("div")({
  textTransform: "uppercase",
  color: "#FFFFFF",
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#2C6842",
  alignItems: "center",
  padding: "10px 0px",
});

const HeaderSignature: FC<any> = ({ children }) => <HeaderComponent>{children}</HeaderComponent>;

export default HeaderSignature;
