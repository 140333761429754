import { useQuery } from "@apollo/client";
import { FC } from "react";
import { CircularProgress, Paper, Typography, Alert, AlertTitle } from "@mui/material";
import { styled } from "@mui/system";
import { getDateStringFromDate } from "utils/date.helper";
import useQueryParams from "hooks/useQueryParams";
import { PAYER_SIGNATURE_BY_TOKEN } from "gql/queries/signature";

import Header from "./components/Header";
import { OrderSignatureForm } from "./OrderSignatureForm";

const LayoutComponent = styled("div")({ padding: "24px" });
const DocumentWrapperComponent = styled("div")({ margin: "20px 0" });

const OrderSignaturePage: FC = () => {
  const queryParams = useQueryParams();
  const { data, loading, error } = useQuery(PAYER_SIGNATURE_BY_TOKEN, {
    variables: { token: queryParams.get("token") },
    fetchPolicy: "no-cache",
  });
  const orderIdentifier = data?.payerSignature?.order?.identifier;
  const orderName = orderIdentifier ? `N°${orderIdentifier}` : "";

  return (
    <>
      <Header>
        <Typography variant="h4">Signature de votre bon de commande</Typography>
      </Header>
      <LayoutComponent>
        <Paper sx={{ marginTop: "32px", padding: "24px" }}>
          {loading ? (
            <CircularProgress sx={{ display: "block", margin: "20vh auto" }} size={52} thickness={4} />
          ) : (
            <>
              <Typography variant="h4">{`Visualisation de votre bon de commande ${orderName}`}</Typography>
              <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                Consultez le bon de commande et veuillez vérifier toutes les informations avant de procéder à sa signature.
              </Typography>
              <DocumentWrapperComponent>
                {error && (
                  <Alert icon={false} severity="error">
                    Votre bdc a été modifié et n’est plus accessible. Veuillez contacter votre commercial.
                  </Alert>
                )}
                {data?.payerSignature?.signedAt && (
                  <Alert icon={false} severity="warning">
                    <AlertTitle>
                      <strong>Ce bon de commande est déjà signé.</strong>
                    </AlertTitle>
                    <Typography>
                      {`Vous avez signé le bon de commande le ${getDateStringFromDate(data.payerSignature.signedAt)}.`}
                    </Typography>
                  </Alert>
                )}
                {data && <OrderSignatureForm data={data.payerSignature} />}
              </DocumentWrapperComponent>
            </>
          )}
        </Paper>
      </LayoutComponent>
    </>
  );
};

export default OrderSignaturePage;
