import { FC, ReactNode } from "react";
import { ThemeProvider, CssBaseline, createTheme } from "@mui/material";

import Content from "../../components/layout/Content";
import Header from "../../components/layout/Header";
import theme from "theme";

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  // get brand data and define theme with its custom data
  const oldTheme = theme;
  // Theme overide exemple
  // theme.palette.primary = {
  //   main: "#1B4281",
  //   dark: "#1B4281",
  //   light: "#1B42810D",
  //   contrastText: "#FFFFFF",
  // };
  // theme.palette.secondary = {
  //   main: "#E64165",
  //   dark: "#E64165",
  //   light: "##E641650D",
  //   contrastText: "#000000",
  // };
  const brandTheme = createTheme(oldTheme);
  return (
    <ThemeProvider theme={brandTheme}>
      <CssBaseline />
      <Header />
      <Content>{children}</Content>
    </ThemeProvider>
  );
};

export default Layout;
