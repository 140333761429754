import { useState, FC } from "react";
import { useMutation } from "@apollo/client";
import { Button, Checkbox, FormControlLabel, Typography, Alert, AlertTitle } from "@mui/material";
import { Done } from "@mui/icons-material";
import { styled } from "@mui/system";
import { useForm } from "react-hook-form";
import { SIGN_ORDER } from "gql/mutations/order";

import DocumentPreview from "./components/DocumentPreview";

const FooterComponent = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const ButtonSign = styled(Button)({
  fontSize: "14px",
  textTransform: "unset",
});

interface OrderSigned {
  onSubmit(): void;
  data: {
    signedAt: string;
    documentUrl: string;
    order: {
      identifier: string;
    };
  };
  response: any;
  error: any;
}
type Inputs = {
  signedAt: string;
};

export const FormSigned: FC<OrderSigned> = ({ onSubmit, data, response, error }) => {
  const [checked, setChecked] = useState(false);
  const { handleSubmit } = useForm<Inputs>({ defaultValues: { signedAt: data?.signedAt } });

  const handleChange = ({ target: { checked } }: any): void => setChecked(checked);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error && (
        <Alert icon={false} severity="error">
          <AlertTitle>
            <strong>Une erreur est intervenue.</strong>
          </AlertTitle>
          Une erreur est intervenue lors de la signature de votre bon de commande.
        </Alert>
      )}
      <DocumentPreview url={data.documentUrl} />
      {!data?.signedAt && (
        <FooterComponent>
          <FormControlLabel
            control={<Checkbox onChange={handleChange} />}
            label={`En cochant cette case, j'accepte et je reconnais avoir pris connaissance du bon de commande n°${data?.order?.identifier}`}
          />
          <ButtonSign
            type="submit"
            variant="contained"
            color="primary"
            disabled={!checked || response}
            sx={{ width: 360, margin: "32px 0", alignSelf: "end" }}
          >
            Signer ce bon de commande
          </ButtonSign>
        </FooterComponent>
      )}
      {error && (
        <Alert icon={false} severity="error">
          <AlertTitle>
            <strong>Une erreur est intervenue.</strong>
          </AlertTitle>
          <Typography>Une erreur est intervenue lors de la signature de votre bon de commande.</Typography>
        </Alert>
      )}
      {response && (
        <Alert icon={<Done />} severity="success">
          <Typography>Merci d&apos;avoir signé le bon de commande.</Typography>
        </Alert>
      )}
    </form>
  );
};

export const OrderSignatureForm: FC<any> = ({ data }) => {
  const [signOrder, { data: response }] = useMutation(SIGN_ORDER);
  const [error, setError] = useState(false);

  const onSubmit = async () => {
    try {
      await signOrder({ variables: { token: data.token } });
    } catch (e) {
      setError(true);
    }
  };

  return <FormSigned data={data} response={response} error={error} onSubmit={onSubmit} />;
};
