import { FC } from "react";
import { AppBar, Toolbar } from "@mui/material";
import { styled } from "@mui/system";

import logoDefault from "../../assets/images/indes-radio-logo.png";

const AppBarComponent = styled(AppBar)(({ theme }) => ({
  height: 170,
  [theme.breakpoints.up("sm")]: {
    height: 284,
  },
  position: "absolute",
  top: 0,
}));

const Logo = styled("img")(({ theme }) => ({
  margin: "4px auto",
  maxHeight: 56,
  [theme.breakpoints.up("sm")]: {
    maxHeight: 88,
    margin: "24px auto",
  },
}));

const Header: FC<{ logo?: string }> = ({ logo = logoDefault }) => {
  return (
    <AppBarComponent position="relative" color="primary" elevation={0}>
      <Toolbar>
        <Logo src={logo} alt="logo" />
      </Toolbar>
    </AppBarComponent>
  );
};

export default Header;
