import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";

import { GraphQLProvider } from "./gql";
import OrderSignaturePage from "./pages/sign/OrderSignature";
import theme from "./theme";
import ErrorPage from "pages/errors/ErrorPage";
import NotFoundException from "exceptions/NotFoundException";
import { logError } from "sentry/logError";

function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary FallbackComponent={ErrorPage} onError={logError}>
        <GraphQLProvider>
          <Router basename={process.env.REACT_APP_BASE_URL}>
            <Routes>
              <Route path="/sign/order" element={<OrderSignaturePage />} />
              <Route path="*" element={<ErrorPage error={new NotFoundException()} />} />
            </Routes>
          </Router>
        </GraphQLProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
