import { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const LayoutIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M5 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5ZM2 5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5Z" />
    <path d="M2 9a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Z" />
    <path d="M9 8a1 1 0 0 1 1 1v12a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1Z" />
  </SvgIcon>
);

export default LayoutIcon;
