import { FC, ReactChild } from "react";
import { Grid, Paper, styled, Typography } from "@mui/material";

type KeyNumberProps = { title: string; description: string; logo: ReactChild };

const KeyNumberPaper = styled(Paper)(({ theme }) => ({
  borderColor: theme.palette.grey[100],
  borderStyle: "solid",
  borderWidth: 1,
  borderRadius: 4,
  padding: 12,
  width: "100%",
}));

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontSize: 28,
  lineHeight: "36px",
  fontWeight: 500,
}));

const LogoElement = styled("div")(({ theme }) => ({
  background: "rgba(27, 66, 129, 0.05)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  padding: theme.spacing(2),
}));

const KeyNumber: FC<KeyNumberProps> = ({ title, description, logo }) => {
  return (
    <KeyNumberPaper elevation={0}>
      <Grid container spacing={2} direction="row" alignItems="center" wrap="nowrap">
        <Grid item>
          <LogoElement>{logo}</LogoElement>
        </Grid>
        <Grid item xs={12} sm>
          <Title>{title}</Title>
          <Typography variant="subtitle2" color="textSecondary">
            {description}
          </Typography>
        </Grid>
      </Grid>
    </KeyNumberPaper>
  );
};

export default KeyNumber;
