import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { init as initSentry } from "@sentry/react";

import App from "./App";
import { SENTRY_CONFIG } from "sentry/config";
import * as serviceWorker from "./serviceWorker";

import "./assets/css/index.css";

initSentry(SENTRY_CONFIG);

const MOUNT_NODE: any = document.getElementById("root");
const root = createRoot(MOUNT_NODE);
if (root) {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}

serviceWorker.unregister();
